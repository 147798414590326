<template>
    <div class="surface-card p-6 border-1 surface-border">
        <update-view-header
            :headline="entity.id ? (entity.name + ' bearbeiten') : 'Benutzergruppe hinzufügen'"
            button-path="/user-groups"
            button-text="Zurück zur Übersicht"
        />

        <form v-on:submit.prevent="submitEntity" v-if="editedEntity">
            <div class="field grid">
                <label class="col-fixed text-700 mt-1 w-4">
                    Aktiv
                </label>
                <div class="col">
                    <checkbox v-model="editedEntity.active" :binary="true"/>
                </div>
            </div>

            <div class="field grid">
                <label class="col-fixed text-700 mt-1 w-4">
                    Name
                </label>
                <div class="col">
                    <InputText
                        class="w-full"
                        type="text"
                        required
                        v-model="editedEntity['name']"
                    />
                    <small class="p-invalid block mt-2" v-for="message in editedEntityErrors['name']" :key="message">
                        {{ message }}
                    </small>
                </div>
            </div>

            <permissions
                v-model="editedEntity['permissions']"
            />

            <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

            <div class="text-center">
                <Button type="submit" label="Speichern"></Button>
            </div>
        </form>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext/InputText";
    import Button from "primevue/button";
    import Permissions from "@/components/form/Permissions";
    import Checkbox from 'primevue/checkbox';
    import UpdateView from '../../mixin/UpdateView';
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";

    export default {
        mixins: [UpdateView],
        components: {
            UpdateViewHeader,
            Permissions,
            Checkbox,
            InputText,
            Button,
        },
        methods: {
            getApiEndpoint() {
                return 'user-groups';
            },
            getCreationAction(entity) {
                return this.$router.push('/user-groups/' + entity.id);
            },
        }
    }
</script>
